'use client';
import { JumpingText } from '@/components/JumpingText';
import styles from './page.module.scss';
import { Button } from '@/components/Button';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/navigation';
import { Arrow } from '@/components/Arrow';

export default function NotFound() {
  const t = useTranslations();
  const router = useRouter();

  const handleClick = () => {
    router.replace('/');
  };

  return (
    <div className={styles.main}>
      <JumpingText text="404" />
      <div className={styles.button}>
        <Button
          text={t('toMain')}
          onClick={handleClick}
          customIcon={<Arrow className={styles.arrow} />}
          animatedIcon
        />
      </div>
    </div>
  );
}
